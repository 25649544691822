import {
  Button,
  Collapse,
  Modal
} from 'bootstrap';

// Make available globally
window.Button = Button;
window.Collapse = Collapse;
window.Modal = Modal;
